<template>
  <div class="page-container">
    <div class="title-container">
      <Title :title="title" />
      <div></div>
    </div>
    <div class="container">
      <ProductFilters @on-filtered="onFilterChange" />
      <div class="flex">
        <div class="flex-none">
          <div class="secondary-side__menu">
            <Menu :items="menuLinks" :disabled="isFiltered" />
          </div>
        </div>
        <div class="flex-grow ml-4 sub-content__area">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  components: {
    Title: () => import(/* webpackPrefetch: true */ "../common/Title"),
    ProductFilters: () =>
      import(/* webpackPrefetch: true */ "./ProductFilters"),
    Menu: () => import(/* webpackPrefetch: true */ "../common/Menu"),
  },
  data: () => ({
    title: "View Product",
    isLoader: true,
    isFiltered: true,
    menuLinks: [
      {
        name: "General Information",
        path: "/product-details/generalinfo",
      },
      {
        name: "Assigned Formulation/CUC",
        path: "/product-details/assignedformulation",
      },
    ],
  }),
  created() {
    this.getCountries();
  },
  methods: {
    ...mapActions(["fetchCountries"]),
    ...mapMutations({
      isMenuOpen: "SET_IS_MENU_OPEN",
      isfiltered: "SET_IS_FILTERED",
    }),
    onFilterChange(val) {
      this.isFiltered = !val;
      this.isfiltered(val);
      this.isMenuOpen(false);
      if (this.$route.name === "productdetails") {
        this.$router.push({ name: "generalinfo" }).catch(() => {});
      } else {
        this.$router.push({ name: this.$route.name }).catch(() => {});
      }
    },
    getCountries() {
      this.fetchCountries();
    },
  },
};
</script>
