var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "title-container" },
      [_c("Title", { attrs: { title: _vm.title } }), _c("div")],
      1
    ),
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("ProductFilters", { on: { "on-filtered": _vm.onFilterChange } }),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "flex-none" }, [
            _c(
              "div",
              { staticClass: "secondary-side__menu" },
              [
                _c("Menu", {
                  attrs: { items: _vm.menuLinks, disabled: _vm.isFiltered }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "flex-grow ml-4 sub-content__area" },
            [_c("router-view")],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }